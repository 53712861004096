<template>
  <ModalContainer
    :is-show-modal="isShowModal"
    class="success-modal"
    @close="closeModal"
  >
    <template #header>
      {{ $t(preparedData.title) }}
    </template>
    <div>
      {{ $t(preparedData.text) }}
    </div>
    <div class="feedback-btn">
      <BaseButton outlined @click="closeModal">{{ $t("Close") }}</BaseButton>
    </div>
  </ModalContainer>
</template>

<script lang="ts">
import ModalContainer from "~/components/modals/ModalContainer.vue";
import BaseButton from "~/components/button/BaseButton.vue";
import { SuccessModalTypes } from "~/types/entities";

const data = {
  [SuccessModalTypes.CONNECT_COMPANY]: {
    title: "Get an Offer",
    text: "Thank you for your request! Your inquiry is important to us. Expect a call from our dedicated manager shortly, who will gather all the necessary details to tailor a personalized offer for your company's unique needs.",
  },
  [SuccessModalTypes.PARTNER]: {
    title: "Become a Partner",
    text: "Thank you for your request! Your inquiry is important to us. Expect a call from our dedicated manager shortly, who will gather all the necessary details to tailor a personalized offer for your company's unique needs.",
  },
  [SuccessModalTypes.QUESTION]: {
    title: "Ask Us Anything",
    text: "Thank you for your request! Your inquiry is important to us.",
  },
};

export default defineComponent({
  components: { BaseButton, ModalContainer },

  props: {
    isShowModal: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    modalType: {
      type: String as PropType<SuccessModalTypes>,
      required: true,
    },
  },

  emits: ["update:isShowModal"],

  setup(props, { emit }) {
    function closeModal() {
      emit("update:isShowModal", false);
    }

    const preparedData = computed(() => {
      return data[props.modalType];
    });

    return { closeModal, preparedData };
  },
});
</script>
